<template>
	<v-sheet>
		<h2 class="font-weight-light">
			All Assessments for Your Organisation
		</h2>
		<p>
			This page provides an overview over all assessments stored for your
			organisation. Please note: You can only edit assessments marked "In
			Progress". Only one assessment can be "In Progress" at any given time.
			Assessments can only be deleted by the owner up to 24h after creation.
		</p>
		<v-list v-for="assessment in assessments" :key="assessment.id">
			<navigation-line-item
				:disabled="loading"
				v-bind="assessment"
			></navigation-line-item>
		</v-list>
		<p class="text-right mt-4" v-if="isLeadership">
			<loading v-if="loading"></loading>
			<create-new-assessment
				:disabled="loading"
				:loading="loading"
				@start="loading = true"
				@end="loading = false"
			></create-new-assessment>
			<delete-assessment></delete-assessment>
		</p>
	</v-sheet>
</template>

<script>
	import Loading from "../ui/Loading.vue";
	import NavigationLineItem from "./NavigationLineItem.vue";
	import CreateNewAssessment from "./CreateNewAssessment.vue";
	import DeleteAssessment from "./DeleteAssessment.vue";
	export default {
		name: "ContinuousModelOverview",
		data: () => {
			return {
				loading: false
			};
		},
		computed: {
			assessments() {
				let assessments = this.$store.getters["assessments/ordered"];
				let haveAssessments = this.$store.getters.assessmentsCanAccess;
				return assessments.filter( a => haveAssessments.includes(a.id))
				// return assessments
			},
			isLeadership() {
				return this.$store.getters.isLeadership;
			}
		},
		components: {
			NavigationLineItem,
			CreateNewAssessment,
			DeleteAssessment,
			Loading
		}
	};
</script>
