<template>
	<v-list-item three-line>
		<v-list-item-icon>
			<v-icon :color="stageColor">{{ stageIcon }}</v-icon>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title>
				{{ assessmentTitle }}
			</v-list-item-title>
			<v-list-item-subtitle>
				<span v-if="created_at">
					Created At:
					{{ mwutils.toReadableDate(created_at) }}
				</span>
				<span v-if="updated_at">
					, Last Updated At:
					{{ mwutils.toReadableDate(updated_at) }}
				</span>
				<v-chip class="ml-2" small v-if="description">
					{{description}}
				</v-chip>
			</v-list-item-subtitle>
			<v-list-item-subtitle v-if="isLeadership">
				<span class="font-weight-bold">
					Leads: {{ leadRespondents.join(", " )}}
				</span>
			</v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-action>
			<v-btn icon color="primary" @click="startAssessment(id)" :disabled="disabled">
				<v-icon>mdi-arrow-right-circle</v-icon>
			</v-btn>
		</v-list-item-action>
	</v-list-item>
</template>

<script>
	export default {
		name: "ContinuousModelLineItem",
		props: {
			disabled: Boolean,
			created_at: Date,
			updated_at: Date,
			stage: String,
			id: String,
			users: Object,
			description: String
		},
		computed: {
			leadRespondents() {
				const allUsers = this.$store.state.users.data
				const users = this.users;
				const me = this.$store.state.auth.data.id;
				const ids = Object.keys(users).filter( id => users[id] == 'leadRespondent');
				return ids.map( id => {
					if( id == me ) {
						return "You";
					}	
					if( allUsers[id] ) {
						return allUsers[id].name
					} 
					return "";
				})
			},
			stageColor() {
				return this.stage == "approved" ? "success" : "primary";
			},
			stageIcon() {
				return this.stage == "approved" ? "mdi-check-circle" : "mdi-circle";
			},
			assessmentTitle() {
				let res = `${this.mwutils.prettyPrint(this.stage, "TITLE")} Assessment`;
				res += `, ${this.created_at.toLocaleString("default", {
					month: "long"
				})}
                 ${this.created_at.getFullYear()}`;
				return res;
			}, 
			isLeadership() {
				return this.$store.getters.isLeadership
			}
		},
		methods: {
			startAssessment(id) {
				this.$store.dispatch("assessment/start", id).then(() => {
					this.$router.push("/assessment");
				});
			}
		}
	};
</script>
