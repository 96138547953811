<template>
	<mw-dialog
		v-model="dialog"
		submit-btn
		@submit="submit"
		cancel-btn
		small
		title="Delete Most Recent Assessment"
	>
		<template v-slot:button>
			<v-btn
				class="ml-2"
				color="success"
				@click="dialog = true"
				:disabled="disableButton"
			>
				Delete Most Recent Assessment
			</v-btn>
			<mw-dialog
				small
				v-if="disableButton"
				v-model="helpDialog"
				cancel-btn
				title="Why can't I delete my assessment"
			>
				<template v-slot:button>
					<v-btn icon @click="helpDialog = true" class="ml-2">
						<v-icon>mdi-help-circle</v-icon>
					</v-btn>
				</template>
				<template v-slot:dialog-body>
					<p v-if="mostRecent">
						You can only delete an assessment created by you in the last 24
						hours. If you have any questions please contact us.
					</p>
					<p v-else>
						There is no most recent assessment to delete
					</p>
				</template>
				<template v-slot:dialog-buttons>
					<v-btn color="success" router-link to="/contact"
						>Contact Support</v-btn
					>
				</template>
			</mw-dialog>
		</template>
		<template v-slot:dialog-body>
			<p>
				You are deleting your most recent assessment. This action can not be
				undone
			</p>
		</template>
	</mw-dialog>
</template>

<script>
	import MwDialog from "../ui/MwDialog.vue";
	export default {
		name: "DeleteAssessment",
		props: {
			disabled: Boolean,
			loading: Boolean
		},
		data: () => {
			return {
				dialog: false,
				helpDialog: false,
				mode: "empty",
				description: null
			};
		},
		computed: {
			user() {
				return this.$store.state.auth.data.id;
			},
			mostRecent() {
				return this.$store.getters["assessments/ordered"][0];
			},
			disableButton() {
				if (!this.mostRecent) {
					return true;
				}
				if (this.mostRecent.created_by !== this.user) {
					return true;
				}
				let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
				return yesterday > this.mostRecent.created_at;
			}
		},
		components: {
			MwDialog
		},
		methods: {
			submit() {
				this.go().then(() => {
					this.dialog = false;
				});
			},
			async go() {
				return await this.$store.dispatch(
					"assessments/setDeleted",
					this.mostRecent.id
				);
			}
		}
	};
</script>
