<template>
	<mw-dialog
		v-model="dialog"
		submit-btn
		@submit="submit"
		cancel-btn
		small
		title="Create New Assessment"
	>
		<template v-slot:button>
			<v-btn color="success" @click="dialog = true" :disabled="disableButton">
				Create New Assessment
			</v-btn>
			<mw-dialog
				small
				v-if="disableButton"
				v-model="helpDialog"
				cancel-btn
				title="Why can't I create a new assessment"
			>
				<template v-slot:button>
					<v-btn icon @click="helpDialog = true" class="ml-2">
						<v-icon>mdi-help-circle</v-icon>
					</v-btn>
				</template>
				<template v-slot:dialog-body>
					<p v-if="loading">
						An assessment is currently being created
					</p>
					<p v-if="mostRecent && mostRecent.stage == 'in_progress'">
						An existing assessment is still in progress. If you think this
						assessment should have been submitted, or if you'd like to
						collaborate on an ongoing assessment, please contact support.
					</p>
				</template>
				<template v-slot:dialog-buttons>
					<v-btn color="success" router-link to="/contact"
						>Contact Support</v-btn
					>
				</template>
			</mw-dialog>
		</template>
		<template v-slot:dialog-body>
			<p>You are creating a new assessment</p>
			<template v-if="mostRecent">
				<p>
					Would you like to start with an empty assessment or a copy of your
					most recent assessment?
				</p>
				<v-checkbox
					v-model="mode"
					hide-details=""
					value="empty"
					label="Empty Assessment"
				></v-checkbox>
				<v-checkbox
					v-model="mode"
					hide-details=""
					value="copy"
					label="Copy Recent Assessment"
				></v-checkbox>
				<p>Please note, polls and logs are not copied</p>
			</template>

			<v-textarea
				label="Description"
				v-model="description"
				placeholder="A brief description of why you are creating this assessment"
			>
			</v-textarea>
		</template>
	</mw-dialog>
</template>

<script>
	import MwDialog from "../ui/MwDialog.vue";
	export default {
		name: "CreateNewAssessment",
		props: {
			disabled: Boolean, 
			loading: Boolean
		},
		data: () => {
			return {
				dialog: false,
				helpDialog: false,
				mode: "empty",
				description: null
			};
		},
		computed: {
			mostRecent() {
				return this.$store.getters["assessments/ordered"][0];
			},
			disableButton() {
				return (
					this.disabled ||
					(this.mostRecent && this.mostRecent.stage == "in_progress")
				);
			}
		},
		components: {
			MwDialog
		},
		methods: {
			clear() {
				this.mode = "empty";
				this.description = null;
			},
			submit() {
				this.$emit("start");
				this.create().then(() => {
					this.$emit("end");
					this.clear();
				});
			},
			async create() {
				const assessment = await this.$store.dispatch("assessments/copy", {
					mode: this.mode,
					from: this.mostRecent ? this.mostRecent.id : null, 
					description: this.description
				});
				return assessment;
			}
		}
	};
</script>
